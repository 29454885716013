import React from 'react';

import Layout from '../components/Layout';
import { UnderlinedButton } from '../components/Button';

const translations = [
  {
    locale: 'en',
    url: '/en/',
  },
];

export default function PageIntrouvablePage() {
  return (
    <Layout translations={translations} logoOptions={{ inverted: true }}>
      <div className="container pt-72">
        <h1 className="sr-only">404 Page introuvable</h1>
        <p className="text-6xl mb-4">
          Oups!
          <br />
          <span className="text-3xl">
            Nous ne pouvons trouver la page que vous recherchez.
          </span>
        </p>
        <UnderlinedButton to="/" className="text-xl">
          Retourner à l'accueil
        </UnderlinedButton>
      </div>
    </Layout>
  );
}
